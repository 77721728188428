// api 地址
const api = {
  salesman: 'admin/Agent/list?agent_type=2'
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.salesman + '&' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
